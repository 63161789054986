<template lang="html">
  <section class="contact">
    <bannerHead dataTitle="Contacto"></bannerHead>
    <breacrumbs dataTitle="Contacto"></breacrumbs>
    <div class="container">
      <formVuevalidate></formVuevalidate>
    </div>
    <div class="form-contact">
      <section class="container-success" v-if="storeForm.alert.active">
        <div class="container-text">
          <p class="title">
            {{storeForm.alert.text}}
          </p>
          <button class="btn-blue" @click="cleanForm()">Aceptar</button>
        </div>
      </section>
      <section class="container-success" v-if="storeForm.sending">
        <div class="container-text gif">
          <Vue3Lottie :animationData="loadingAnimation" :height="auto" width="100%" />
        </div>
      </section>
      <Form id="formContact">
        <div class="column">
          <label for="dni">DNI*<br>
            <span class="mss-error" v-if="v$.dni.$error">
              {{ v$.dni.$errors[0].$message }}
            </span>
          </label>
          <input id="dni" name="dni" type="text" placeholder="00000000Y" v-model="state.dni"
            :class="[v$.dni.$error ? 'class-error' : '']">

        </div>
        <div class="column">
          <label for="name">Nombre y Apellidos*<br>
            <span class="mss-error" v-if="v$.name.$error">
              {{ v$.name.$errors[0].$message }}
            </span>
          </label>
          <input id="name" name="name" type="text" placeholder="Nombre Apellido Apellido" v-model="state.name"
            :class="[v$.name.$error ? 'class-error' : '']">
        </div>

        <div class="column">
          <label for="deparment">Departamento*<br>
            <span class="mss-error" v-if="v$.deparment.$error">
              {{ v$.deparment.$errors[0].$message }}
            </span>
          </label>
          <input id="deparment" name="deparment" type="text" placeholder="Departamento" v-model="state.deparment"
            :class="[v$.deparment.$error ? 'class-error' : '']">
        </div>
        <div class="column">
          <label for="email">Email*<br>
            <span class="mss-error" v-if="v$.email.$error">
              {{ v$.email.$errors[0].$message }}
            </span>
          </label>
          <input id="email" name="email" type="text" placeholder="email@email.com" v-model="state.email"
            :class="[v$.email.$error ? 'class-error' : '']">
        </div>
        <div class="column">
          <label for="phone">Teléfono de contacto*<br>
            <span class="mss-error" v-if="v$.phone.$error">
              {{ v$.phone.$errors[0].$message }}
            </span>
          </label>
          <input id="phone" name="phone" type="text" placeholder="902010203" v-model="state.phone"
            :class="[v$.phone.$error ? 'class-error' : '']">

        </div>
        <div class="column" v-if="storeForm.motives">
          <label for="motive_id">Motivo*<br>
            <span class="mss-error" v-if="msg_motive">{{ selectErrors }}</span>
          </label>
          <select v-model="state.motive_id" id="motive_id" name="motive_id" :class="[msg_motive ? 'class-error' : '']">
            <option value='0'>Selecciona el motivo*</option>
            <option :value="motive.id" v-for="(motive, key ) in storeForm.motives" :key="key"
              :title="motive.description">
              {{motive.name}}</option>
          </select>

        </div>
        <div class="column">
          <label for="message">Descripción*<br>
            <span class="mss-error" v-if="v$.message.$error">
              {{ v$.message.$errors[0].$message }}
            </span>
          </label>

          <textarea id="message" name="message" type="text" placeholder="Mensaje..." v-model="state.message"
            :class="[v$.message.$error ? 'class-error' : '']"></textarea>

        </div>

        <div class="column">
          <label for="file">Anexar documentación</label>
          <input id="file" name="file" type="file" class="input-styled message" />

          <div class="checkbox-styled w-100">
            <input id="rgpd" name="rgpd" type="checkbox" v-model="state.rgpd">

            <label for="rgpd">
              He leido y acepto la
              <a href="/politica-de-privacidad" target="_blank"
                title="Política de privacidad">Política de Privacidad</a><br>
              <span class="mss-error" v-if="v$.rgpd.$error">
                {{ v$.rgpd.$errors[0].$message }}
              </span>
            </label>
          </div>
        </div>

        <div class="row-center w-100">
          <span class="btn-white-border" @click="submitForm()">Enviar</span>
        </div>
      </Form>
    </div>
    <div class="others ">
      <div class="container">
        <p class="title-line -small">Para otras consultas</p>
        <div class="grid-3">
          <a href="mailto:admin.personal@ufv.es" class="grid box-blue -darkBig"
            title="Correo electrónico administración">
            <img src="/img/icons/icon-email.svg" alt="Icono email">
            <p class="title">Administración de Personas y Relaciones Laborales</p>
            <span>
              admin.personal@ufv.es
            </span>
          </a>
          <a href="mailto:serviciodeprevencion@ufv.es" class="grid box-blue -dark"
            title="Correo electrónico seguridad. salud y bienestar">
            <img src="/img/icons/icon-email.svg" alt="icono email">
            <p class="title">Seguridad, Salud
              y bienestar
            </p>
            <span>serviciodeprevencion@ufv.es</span>
          </a>
          <a href="mailto:talentoufv@ufv.es" class="grid box-blue -medium"
            title="Correo electrónico selección y talento">
            <img src="/img/icons/icon-email.svg" alt="icono email">
            <p class="title">Selección y Talento
            </p>
            <span>talentoufv@ufv.es</span>
          </a>
          <!-- <a href="mailto:sistemas@ufv.es" class="grid box-blue -light">
            <img src="/img/icons/icon-email.svg" alt="">
            <p class="title"> Sistemas
            </p>
            <span>sistemas@ufv.es</span>
          </a> -->
        </div>
      </div>

    </div>
  </section>
</template>

<script lang="js">
  import bannerHead from '@/components/banner-head.vue'
  import breacrumbs from '@/components/breacrumbs.vue'
  import { formStore } from '@/stores/forms'
  import { contentStore } from '@/stores/contents'
  import { Vue3Lottie } from 'vue3-lottie'
  import 'vue3-lottie/dist/style.css'
  import loadingAnimation from '@/lotties/loading.json'
  // import axios from 'axios'
  import useValidate from "@vuelidate/core";
  import { reactive, computed } from "vue";
  import { required, email, helpers } from "@vuelidate/validators";
  const requiredMsg = helpers.withMessage('Este campo es obligatorio', required);
  const phoneCheck = helpers.regex(/^[0-9]{7,9}$/);
  const storeForm = formStore();
  const storeContent = contentStore();
  export default {
    name: 'contact',
    props: [],
    components: {
      bannerHead,
      breacrumbs,
      Vue3Lottie
    },
    setup() {
      const state = reactive({
        dni: '',
        name: '',
        email: '',
        phone: '',
        deparment: '',
        motive_id: '0',
        message: '',
        rgpd: '',
        file: '',
      })
      const rules = computed(() => {
        return {
          dni: {
            required: requiredMsg,

          },
          name: {
            required: requiredMsg,
          },
          email: {
            required: requiredMsg,
            email: helpers.withMessage('Por favor introduzca un email válido', email)
          },

          phone: {
            required: requiredMsg,
            phoneCheck: helpers.withMessage('Por favor introduzca un teléfono válido', phoneCheck)
          },
          deparment: {
            required: requiredMsg,
          },
          motive_id: {
            required: requiredMsg
          },
          message: {
            required: requiredMsg,
          },
          rgpd: {
            required: requiredMsg,
          },

        }
      })

      const v$ = useValidate(rules, state)

      return {
        state,
        v$,
        storeForm,
        storeContent
      }
    },
    data() {
      return {
        loadingAnimation,
        msg_motive: false,
      }
    },
    methods: {
      cleanForm() {
        storeForm.alert.active = false;
        storeForm.alert.text = '';
        this.$router.go()
      },
      async submitForm() {
        if (this.state.motive_id == 0) {
          this.msg_motive = true
        } else {
          this.msg_motive = false
        }
        var isFormCorrect = await this.v$.$validate()
        if (isFormCorrect) {
          var formElement = document.getElementById("formContact");
          var formData = new FormData(formElement);
          storeForm.formContact(formData).then((response) => {
            if (response.status == 200) {
              storeForm.alert.active = true;
              storeForm.alert.text = 'El formulario se ha mandado correctamente. Pronto nos pondremos en contacto con usted.';
              this.state = {
                dni: '',
                name: '',
                email: '',
                phone: '',
                deparment: '',
                motive_id: '0',
                message: '',
                rgpd: '',
                file: '',
              }
            }
          })
        }
      }


    },
    mounted() {
      storeForm.loadMotives()
    },
    computed: {
      selectErrors() {
        var msg = ''
        if (this.state.motive_id == 0) {
          msg = 'Por favor seleccione una opción'
        } else {
          msg = ''
        }
        return msg
      }
    }
  }


</script>

<style scoped lang="scss">
  option {
    cursor: pointer;
  }

  .mss-error {
    color: orange;
    font-weight: 400;
    font-size: 16px;
    margin-top: 5px;
    display: inline-block;
  }

  .class-error {
    border: 1px solid orange;
  }
</style>